<script lang="ts" setup>
import type { ICompanies } from '~/composables/useAPI/types'
import type { TanstackTableColumn } from '@ui/components/TanstackTable'
import { useCompanyColumns } from './composables/useCompanyColumns'

interface Props {
  data: ICompanies[]
  title?: string
  loading?: boolean
  error?: string
}
const props = withDefaults(defineProps<Props>(), {
  data: undefined,
  title: undefined,
  loading: false,
  error: undefined,
})

defineEmits(['cancel', 'confirm'])

const columns = useCompanyColumns()
const { createDivisionURL } = useDivisions()
</script>

<template>
  <div>
    <div
      v-if="!loading && error"
      class="rounded-lg border border-red-500 bg-red-50 p-4 text-red-500"
    >
      {{ error }}
    </div>

    <TanstackTable
      v-else
      :data="data"
      :columns="columns"
      :loading="loading"
      sortable
      aria-describedby="divisions"
    >
      <template #empty>
        <div
          class="rounded-lg border border-yellow-500 bg-yellow-100 bg-opacity-10 p-4 text-yellow-500"
        >
          <h2>{{ title }}</h2>
          There is no company, please, add one
        </div>
      </template>

      <template
        #item-country="{ value: flag }: TanstackTableColumn<ICompanies>"
      >
        <span
          class="mask mask-squircle flex h-[24px] w-[24px] max-w-min overflow-hidden rounded-md"
        >
          <Icon :name="flag" class="text-2xl" filled />
        </span>
      </template>

      <template
        #item-name="{ item: company, value }: TanstackTableColumn<ICompanies>"
      >
        <NuxtLink
          :to="createDivisionURL(undefined, company.id)"
          class="font-bold hover:underline"
        >
          {{ value }}
        </NuxtLink>
      </template>

      <template #item-tradingName="{ value }: TanstackTableColumn<ICompanies>">
        {{ value ?? '-' }}
      </template>

      <template #item-notes="{ value }: TanstackTableColumn<ICompanies>">
        {{ value }}
      </template>

      <template
        #item-actions="{ item: division }: TanstackTableColumn<ICompanies>"
      >
        <div class="flex max-w-min gap-4">
          <Button
            size="sm"
            color="opacityPrimary"
            :to="createDivisionURL('/settings/users', division.id)"
          >
            Users
          </Button>

          <Button
            size="sm"
            color="opacityPrimary"
            :to="createDivisionURL('/settings', division.id)"
            class="min-w-max"
          >
            Setup
          </Button>
        </div>
      </template>
    </TanstackTable>
  </div>
</template>
