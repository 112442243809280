import { createColumnHelper } from '@tanstack/vue-table'
import type { ICompanies } from '~/composables/useAPI/types'

export const useCompanyColumns = () => {
  const { $can } = useNuxtApp()
  const columnHelper = createColumnHelper<ICompanies>()
  return [
    columnHelper.display({
      id: 'country',
      cell: ({ row }) => `flags-1x1/${row.original.countryId.toLowerCase()}`,
      enableSorting: false,
    }),
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('tradingName', {
      header: 'Trading Name',
    }),
    columnHelper.accessor('notes', {
      header: 'Notes',
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      enableSorting: false,
      meta: {
        visible: () => $can('manage', 'Organization'),
      },
    }),
  ]
}
